/* playfair-display-regular - latin-ext_latin */
@font-face {
  font-family: "Playfair Display";
  font-style: normal;
  font-weight: 400;
  src: url("../assets/fonts/playfair-display-v13-latin-ext_latin-regular.eot"); /* IE9 Compat Modes */
  src: local("Playfair Display Regular"), local("PlayfairDisplay-Regular"),
    url("../assets/fonts/playfair-display-v13-latin-ext_latin-regular.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */
      url("../assets/fonts/playfair-display-v13-latin-ext_latin-regular.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("../assets/fonts/playfair-display-v13-latin-ext_latin-regular.woff")
      format("woff"),
    /* Modern Browsers */
      url("../assets/fonts/playfair-display-v13-latin-ext_latin-regular.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("../assets/fonts/playfair-display-v13-latin-ext_latin-regular.svg#PlayfairDisplay")
      format("svg"); /* Legacy iOS */
}
/* playfair-display-700 - latin-ext_latin */
@font-face {
  font-family: "Playfair Display";
  font-style: normal;
  font-weight: 700;
  src: url("../assets/fonts/playfair-display-v13-latin-ext_latin-700.eot"); /* IE9 Compat Modes */
  src: local("Playfair Display Bold"), local("PlayfairDisplay-Bold"),
    url("../assets/fonts/playfair-display-v13-latin-ext_latin-700.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */
      url("../assets/fonts/playfair-display-v13-latin-ext_latin-700.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("../assets/fonts/playfair-display-v13-latin-ext_latin-700.woff")
      format("woff"),
    /* Modern Browsers */
      url("../assets/fonts/playfair-display-v13-latin-ext_latin-700.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("../assets/fonts/playfair-display-v13-latin-ext_latin-700.svg#PlayfairDisplay")
      format("svg"); /* Legacy iOS */
}

/* fira-sans-regular - latin-ext_latin */
@font-face {
  font-family: "Fira Sans";
  font-style: normal;
  font-weight: 400;
  src: url("../assets/fonts/fira-sans-v8-latin-ext_latin-regular.eot"); /* IE9 Compat Modes */
  src: local("Fira Sans Regular"), local("FiraSans-Regular"),
    url("../assets/fonts/fira-sans-v8-latin-ext_latin-regular.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */
      url("../assets/fonts/fira-sans-v8-latin-ext_latin-regular.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("../assets/fonts/fira-sans-v8-latin-ext_latin-regular.woff")
      format("woff"),
    /* Modern Browsers */
      url("../assets/fonts/fira-sans-v8-latin-ext_latin-regular.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("../assets/fonts/fira-sans-v8-latin-ext_latin-regular.svg#FiraSans")
      format("svg"); /* Legacy iOS */
}
/* fira-sans-600 - latin-ext_latin */
@font-face {
  font-family: "Fira Sans";
  font-style: normal;
  font-weight: 600;
  src: url("../assets/fonts/fira-sans-v8-latin-ext_latin-600.eot"); /* IE9 Compat Modes */
  src: local("Fira Sans SemiBold"), local("FiraSans-SemiBold"),
    url("../assets/fonts/fira-sans-v8-latin-ext_latin-600.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("../assets/fonts/fira-sans-v8-latin-ext_latin-600.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("../assets/fonts/fira-sans-v8-latin-ext_latin-600.woff")
      format("woff"),
    /* Modern Browsers */
      url("../assets/fonts/fira-sans-v8-latin-ext_latin-600.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("../assets/fonts/fira-sans-v8-latin-ext_latin-600.svg#FiraSans")
      format("svg"); /* Legacy iOS */
}
