@import "./fonts.scss";

html {
  // overflow-x: hidden;
  // font-family: "Playfair Display", serif;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  height: auto;
  position: relative;
  .line {
    position: absolute;
    height: 100%;
    width: 1px;
    background: rgba(0, 0, 0, 0.1);
    z-index: 2;
  }
  #line1 {
    left: 137px;
    @media (max-width: 1200px) {
      left: 138px;
    }
    @media (max-width: 650px) {
      left: 50px;
    }
    @media (max-width: 400px) {
      left: 20px;
    }
  }
  #line2 {
    left: calc(39% + 30px);
  }
  #line3 {
    right: 137px;
    @media (max-width: 1200px) {
      right: 138px;
    }
    @media (max-width: 650px) {
      right: 50px;
    }
    @media (max-width: 400px) {
      right: 20px;
    }
  }
}

body {
  margin: 0;
  padding: 0;
}

.lang {
  position: fixed;
  color: white;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 138px;
  height: 134px;
  top: 0;
  right: 0;
  font-family: "Fira Sans";
  z-index: 101;
  cursor: pointer;
  text-transform: uppercase;
  mix-blend-mode: difference;
  @media (max-width: 925px) {
    display: none;
  }
}

.burger--menu {
  position: fixed;
  width: 100vw;
  height: 100vh;
  max-height: 100vh !important;
  // padding: 10% 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  background: #fff;
  margin-right: 138px;
  z-index: 3;
  justify-content: space-around;

  @media (min-width: 925px) {
    display: none !important;
  }
  a {
    font-family: "Playfair Display";
    font-style: normal;
    line-height: 87px;
    font-size: 36px;
    text-decoration: none;
    color: #000;
    cursor: pointer;
    // padding: 25px;
    @media (max-height: 600px) {
      line-height: 47px;
      font-size: 26px;
    }
  }
  a.active {
    // color: #d2c3b4;
    font-weight: bold;
    position: relative;
    transition: 0.5s;
  }
  svg {
    position: absolute;
    z-index: -1;
  }
  .lang--burger {
    // margin-top: 25%;
    color: #000;
    font-weight: bold;
    font-family: "Fira Sans";
    cursor: pointer;
    text-transform: uppercase;
    margin-bottom: 40px;
  }
}
